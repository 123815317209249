<template>
  <section v-if="mostRead && mostRead.length">
    <h3 class="text-h4 mb-4 text-center primary--text">
      {{ $t('mostOrderedProg') }}
    </h3>
    <v-row>
      <PostFeedCard
        v-for="program in mostRead"
        :key="program.id"
        :size="3"
        :value="program.post_id"
        type="program"
        :tag_id="2"
      />
    </v-row>
    <v-btn class="mt-10" text color="primary" link to="/posts?type=2">{{
      $t('viewAll')
    }}</v-btn>
  </section>
</template>

<script>
import ApiService from '@/services/api.service';
export default {
  components: {
    PostFeedCard: () => import('@/components/PostFeedCard'),
  },
  data() {
    return {
      loading: false,
      mostRead: null,
    };
  },
  methods: {
    async fetchData() {
      console.log('start here');
      try {
        this.loading = true;
        const { data } = await ApiService.get(
          `items/posts_tags?fields=*,post_id.*,post_id.featured_image.id,post_id.featured_image.data.full_url&filter[post_id.status]=published&filter[tag_id]=2&limit=3`
        );
        this.mostRead = data.data;
        console.log('data.data', data.data.length);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style></style>
